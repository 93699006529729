import 'bootstrap/dist/js/bootstrap.bundle.min'
import '@Assets/Scss/main.scss'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {lazy, Suspense} from "react";

const Search = lazy(() => import('@Pages/Search'));
const DefaultLayout = lazy(() => import('@Layouts/Default'));
const Landing = lazy(() => import('@Pages/Landing'));
const Course = lazy(() => import('@Pages/Course'));
const Admin = lazy(() => import('@Layouts/Admin/MainLayout'));
const AdminPage = lazy(() => import('@Pages/Admin'));
const AboutContentMangementPage = lazy(() => import('@Pages/Admin/About'));
const CourseDetailAdminPage = lazy(() => import('@Pages/Admin/CourseDetail'));
const Installament = lazy(() => import('@Pages/Installament'));
const Register = lazy(() => import('@Pages/Register'));
const TermMangementPage = lazy(() => import('@Pages/Admin/Term'));
const Contactus = lazy(() => import('@Pages/Contactus'));
const CourseReport = lazy(() => import('@Pages/CourseReport'));
const Report = lazy(() => import('@Pages/Admin/Report'));


function App() {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<BrowserRouter>
				<Routes>
					<Route path="/" exact element={<DefaultLayout/>}>
						<Route path="" element={<Landing/>}/>
						<Route path="s" element={<Search/>}/>
						<Route path="course/:id" element={<Course/>}/>
						<Route path="contactus" element={<Contactus/>}/>
						<Route path="installment" element={<Installament/>}/>
						<Route path="installment/:id" element={<Installament/>}/>
						<Route path="register" element={<Register/>}/>
					</Route>
					<Route path="admin" element={<Admin/>}>
						<Route path="" element={<AdminPage/>}/>
						<Route path="course/:id" element={<CourseReport/>}/>
						<Route path="about" element={<AboutContentMangementPage/>}/>
						<Route path="term" element={<TermMangementPage/>}/>
						<Route path="report" element={<Report />} />
						<Route path="course-detail/create" element={<CourseDetailAdminPage/>}/>
						<Route path="course-detail/:id" element={<CourseDetailAdminPage/>}/>
					</Route>
				</Routes>
			</BrowserRouter>
		</Suspense>
	);
}

export default App;
